import React from 'react';


import { ButtonControlComponent } from '@components/controls/button/button-control.component';
import { TeamTableComponent } from '../table/table.component';
import { UploadFileContainer } from '@components/bulk-team-upload/state/upload.container';
import { ViewPackageModal } from '@components/admin/view-package/view-package.component';

import { AccountType, UserType, Package, ContractorType } from '@shared/types/backend.types';
import { ThemeType } from '@shared/types/theme.type';

import { FormattedMessage } from 'react-intl';
import injectSheet from 'react-jss';
import classnames from 'classnames';


export interface IAdminInviteTeam {
  isSubmittingTeam: boolean;
  isSelectedAccount: boolean;
  viewContractorModal: boolean
  user: UserType;
  companyContractors: ContractorType[]
  company?: AccountType;
  packages: Package[];
  seatsDetails: any;
  dataSource: any;
  validateTeam: boolean;
  classes: any
  setContractorModal: any
  handleFileImport: (users: any) => void;
  handleAddTableItem: () => void;
  handleRemoveTableItem: (key: number) => void;
  handleSubmit: () => void;
  handleSelectCompany: (accountID: number) => void;
  handleUpdateForm: (targetKey: string, value: any, key: string | number) => void;
}

export const TeamInviteStyled = (props: IAdminInviteTeam) => {
  const {
    user,
    companyContractors,
    seatsDetails,
    dataSource,
    validateTeam,
    isSubmittingTeam,
    viewContractorModal,
    classes,
  } = props;
  const {

    handleAddTableItem,
    handleRemoveTableItem,
    handleSubmit,
    handleFileImport,
    handleUpdateForm,
    setContractorModal,
  } = props;

  const { formTitleStyles, formMessageStyles } = classes;


  return (
    <div className="sa__container">

      <div className='sa__box'>

        <div className='sa__box-heading'>
          <div>
            <h1 className={classnames(formTitleStyles, 'sa__box-heading-title')}>
              <FormattedMessage id="dashboard.user.invite.formTitle" />
            </h1>
            <h3 className={classnames(formMessageStyles, 'invite__form-message')}>
              <FormattedMessage id="dashboard.user.invite.formMessage" />
            </h3>
          </div>

          <ViewPackageModal seatsDetails={seatsDetails} />
        </div>

        <div className='invite-team__table-container sa__box-content'>
          <UploadFileContainer handleFileImport={handleFileImport} user={user} />

          <TeamTableComponent
            user={user}
            companyContractors={companyContractors}
            setContractorModal={setContractorModal}
            viewContractorModal={viewContractorModal}
            users={dataSource}
            packages={seatsDetails}
            handleSort={() => console.log('handle sort')}
            handleUpdateForm={handleUpdateForm}
            handleAddTableItem={handleAddTableItem}
            handleRemoveTableItem={handleRemoveTableItem}
          />

          <ButtonControlComponent
            text={'Invite'}
            color="green"
            size="large"
            style={{ float: 'right', margin: '30px 0px', fontSize: 20 }}
            onClick={() => handleSubmit()}
            loading={isSubmittingTeam}
            disabled={!validateTeam}
          />
        </div>
      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  formTitleStyles: {
    color: theme.black,
  },

  selectStyles: {
    fontWeight: 'normal',
  },
});

export const InviteTeamComponent = injectSheet(styles)(TeamInviteStyled);
