import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Form, Input, Spin } from 'antd';
import { IAppState } from '@rdx/root.reducer';
import { ErrorMessage } from '@shared/types/utils.types';
import { ScheduleType, UserType } from '@shared/types/backend.types';
import axios from 'axios';
import {
  fetchAccountsScheduleAction,
  removeScheduleAction,
  updateScheduleAction,
} from '@app/back-office/schedule-job/state/schedule.actions';
import { removeBackofficeErrorAction } from '@app/back-office/professional.action';
import {
  getProfessionalIsLoading,
  getErrorMessages,
  getSchedule,
} from '@app/back-office/professional.selector';
import ScheduleTableComponent from '../view/schedule.component';
import { openNotificationWithIcon } from '@components/controls/notification/notification.component';
import { getSessionUser } from '@app/session/session.selector';
import Cookies from 'js-cookie';
import {
  DELETE_SCHEDULED_JOB_API,
  GET_SCHEDULED_JOBS_API,
  UPDATE_SCHEDULED_JOB_API,
  API_URL,
} from '@constants/endpoints';
import { ScheduleFetchPayload } from '../schedule';
import useForm from 'antd';
import SASpin from '@components/ui/accordian/spin';
import CenteredLoader from '@components/ui/accordian/spinner';
interface IScheduleLockedProps {
  user?: any;
  isLoading: boolean;
  errorMessages: { [key: string]: ErrorMessage | undefined };
  schedule: ScheduleType[];
  fetchAccountsScheduleAction: (payload: ScheduleFetchPayload) => void;
  removeErrorAction: (code: string) => void;
}

const tokenKey = `Bearer ${Cookies.get('sessionKey')}`;

const ScheduleLocked: React.FC<IScheduleLockedProps> = ({
  user,
  isLoading,
  errorMessages,
  schedule,
  fetchAccountsScheduleAction,
  removeErrorAction,
}) => {
  const [editingSchedule, setEditingSchedule] = useState<ScheduleType | null>(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID });
    }
  }, [user, fetchAccountsScheduleAction]);

  useEffect(() => {
    if (Object.keys(errorMessages).length > 0) {
      Object.values(errorMessages).forEach(error => {
        if (error) {
          openNotificationWithIcon('error', error.title, error.message, '');
        }
      });
      Object.keys(errorMessages).forEach(code => {
        removeErrorAction(code);
      });
    }
  }, [errorMessages, removeErrorAction]);

  const handleUpdateSchedule = (values: any) => {
    if (user && editingSchedule) {
      dispatch(
        updateScheduleAction.request({
          project: { ...editingSchedule, ...values },
          accountID: user.user_metadata.accountID,
        }),
      );
      setEditingSchedule(null);
      setEditModalVisible(false);
    }
  };

  const handleRefresh = async (): Promise<void> => {
    if (user) {
      fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID });
    }
  };

  const handleSend = async (jobDetails: any) => {
    console.log(jobDetails);
    console.log(user);
    if (user) {
      let type = jobDetails.activityType;
      const Admin_Api_Key = user.user_metadata.acAPIKey;
      let selectProjectId = jobDetails.projectId || 0;
      let safetyPlanName = jobDetails.safetyProgram;
      const reportUrl = API_URL;
      if (type === 'submissions_report') {
        const url =
          reportUrl +
          '/reports/' +
          Admin_Api_Key +
          '/submissions-export?projectID=' +
          selectProjectId +
          '&safetyProgram=' +
          safetyPlanName;
        window.open(url, '_blank');
      } else if (type === 'ca_report') {
        const url =
          reportUrl +
          '/reports/' +
          Admin_Api_Key +
          '/ca-export?userID=' +
          user.user_id +
          '&projectID=' +
          selectProjectId;
        window.open(url, '_blank');
      } else if (type === 'ptp_submissions_report') {
        const url =
          reportUrl +
          '/reports/' +
          Admin_Api_Key +
          '/ptp-submissions-export?projectId=' +
          selectProjectId;

        window.open(url, '_blank');
      } else if (type === 'cc_report') {
        const url = reportUrl + '/reports/' + Admin_Api_Key + '/cc-export?userID=' + user.user_id;

        window.open(url, '_blank');
      } else if (type === 'projects_report') {
        const url =
          reportUrl + '/reports/' + Admin_Api_Key + '/projects-export?userID=' + user.user_id;

        window.open(url, '_blank');
      } else if (type === 'associates_report') {
        const url =
          reportUrl + '/reports/' + Admin_Api_Key + '/associates-export?userID=' + user.user_id;

        window.open(url, '_blank');
      }
    }
  };

  const handleRemove = async (scheduleId: string) => {
    setLoading(true);
    if (user) {
      const endpoint = DELETE_SCHEDULED_JOB_API.replace(':scheduleJobId', scheduleId).replace(
        ':id',
        `${user.user_metadata.accountID}`,
      );
      try {
        await axios.delete(endpoint, { headers: { Authorization: tokenKey } });
        fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID });
      } catch (error) {
        console.error('Error deleting schedule:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = async (scheduleId: string, jobDetails: any) => {
    setLoading(true);
    if (user) {
      const endpoint = UPDATE_SCHEDULED_JOB_API.replace(':id', `${user.user_metadata.accountID}`);

      let status = '';

      const requestBody = {
        scheduleJobId: scheduleId,
        jobTitle: jobDetails.jobTitle,
        jobDescription: jobDetails.jobDescription,
        jobFrequency: jobDetails.jobFrequency,
        JobTimezone:jobDetails.timezone_txt,
        JobFrequencyValue:'0',
        jobTime: jobDetails.jobTime,
        recipientEmails: jobDetails.recipientEmails,
        status: jobDetails.status ? 'active' : 'inactive',
      };

      console.log(requestBody);
      try {
        if (scheduleId) {
          await axios.put(`${endpoint}`, requestBody, {
            headers: { Authorization: tokenKey },
          });
        } else {
          await axios.post(endpoint, requestBody, {
            headers: { Authorization: tokenKey },
          });
        }
      } catch (error) {
        console.error('Error scheduling job:', error);
      } finally {
        setLoading(false);
        if (user) {
          fetchAccountsScheduleAction({ accountID: user.user_metadata.accountID });
        }
      }
    }
  };

  return (
    <>
      {loading ? (
        <CenteredLoader /> // Show loading spinner
      ) : (
        <ScheduleTableComponent
          schedules={schedule}
          isLoading={isLoading}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          handleSend={handleSend}
          handleToggleStatus={handleEdit}
          user={user}
          fetchSchedules={handleRefresh}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  isLoading: getProfessionalIsLoading(state),
  errorMessages: getErrorMessages(state),
  schedule: getSchedule(state),
});

const mapDispatchToProps = {
  fetchAccountsScheduleAction: fetchAccountsScheduleAction.request,
  removeErrorAction: removeBackofficeErrorAction.trigger,
};

export const ScheduleLockedContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleLocked);
