import React, { useState, useReducer, useEffect, SetStateAction } from 'react';

import { IAppState } from '@rdx/root.reducer';

import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { InviteTeamComponent } from '../view/invite-team.component';
import { getSessionUser } from '@app/session/session.selector';

import { getList, arrayToObject } from '@shared/helpers/state-caster';
import { UserType, AccountType, RoleType, Package, PackageSeatDetail, ContractorType } from '@shared/types/backend.types';
import {
  getEmployeesValidated,
  validateTeam,
} from '@app/back-office/programs/programs.validations';
import { inviteTeamPayload } from '../invite-team';
import { baseTheme } from '@shared/themes/presets/base.theme';

import { submitInviteTeamAction } from './invite-team.saga';
import { fetchCompaniesAction, fetchPackagesAction } from '@app/admin/admin.action';
import { ROLE } from '@constants/roles';
import { STATUS } from '@constants/status';

import { fetchContractorsAction } from '@app/admin/accounts/contractors/state/contractors.actions';
import { getAccountsContractors } from '@app/admin/accounts/contractors/state/contractors.selector';

import { getCompanies, getPackages } from '@app/admin/invite/state/invite.selector';
import { fetchSeatsInfoAction } from '@app/admin/accounts/state/accounts.actions';
import { getSeatsDetails } from '@app/admin/accounts/state/accounts.selector';

import { getAccountsTeam } from '@app/admin/accounts/team/state/team.selector';
import { fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';

export interface InviteTeamContainer {
  user: any;
  isLoading: boolean;
  companies: AccountType[];
  companyContractors: ContractorType[]
  packages: Package[];
  seatsInformation: PackageSeatDetail[];
  hasSubmittedTeam: boolean;
  errorMessages: any;
  selectedAccountID?: number;
  fetchCompaniesAction: () => void;
  submitInviteTeamAction: (payload: inviteTeamPayload) => void;
  fetchPackagesAction: (selectedAccountID: number) => void;
  fetchSeatsInfo: (payload: any) => void;
  fetchAccountUsersInfo: (payload: any) => void;
  fetchContractorsAction: (selectedAccountID: number) => void;
}

const InviteTeam = (props: InviteTeamContainer) => {
  const { user, isLoading, companies, companyContractors, hasSubmittedTeam, errorMessages, seatsInformation, packages } = props;
  const { submitInviteTeamAction, fetchCompaniesAction, fetchSeatsInfo, fetchAccountUsersInfo, fetchContractorsAction } = props;

  //const [isModalVisible, setIsModalVisible] = useState(false);

  const [isRemoving, setIsRemoving] = useState(false);
  const [viewContractorModal, setContractorModal] = useState(false)
  const [selectedAccountID, setSelectedAccountID] = useState<number | undefined>(undefined)
  const [seatsDetails, setSeatsInformation] = useState<SetStateAction<any>>({});
  const [isValidSeat, setIsValidSeat] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const initialState = {
    accountTeam: {},
    teamCachedCounter: 0,
    selectedCompany: undefined,
  };


  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setSelectedAccountID(user.user_metadata.accountID)
  }, [user])

  useEffect(() => {
    if (selectedAccountID)
      fetchContractorsAction(selectedAccountID);
  }, [selectedAccountID]);

  useEffect(() => {
    setIsRemoving(false);
    setIsSubmited(false);

    if (companies.length === 0) {
      fetchCompaniesAction();
    }
    handleSelectCompany(user.user_metadata.accountID);
  }, [companies]);

  useEffect(() => {
    setSeatsInformation(seatsInformation);
  }, [seatsInformation])


  function reducer(state: any, action: any) {
    switch (action.type) {
      case 'ADD_USER':
        return {
          ...state,
          teamCachedCounter: state.teamCachedCounter + 1,
          accountTeam: {
            ...state.accountTeam,
            [action.user.key]: { ...action.user },
          },
        };

      case 'REMOVE_USER':
        const updatedTeam = { ...state.accountTeam };
        delete updatedTeam[action.key];
        return {
          ...state,
          teamCachedCounter: state.teamCachedCounter - 1,
          accountTeam: updatedTeam,
        };

      case 'ADD_FILE_USERS':
        return {
          ...state,
          teamCachedCounter: action.usersCounter,
          accountTeam: { ...action.users },
        };

      case 'UPDATE_USER':
        return {
          ...state,
          accountTeam: {
            ...state.accountTeam,
            [action.key]: action.user,
          },
        };

      case 'SELECT_COMPANY':
        return {
          ...state,
          selectedCompany: action.accountID,
        };

      case 'CLEAN_USERS':
        return {
          ...state,
          accountTeam: {},
        };
    }
  }


  useEffect(() => {

    if (isValidSubmitTeam && !isLoading) {
      if (hasSubmittedTeam) {
        Swal.close();
        Swal.fire({
          title: 'Done!',
          text: `Team has been invited.`,
          type: STATUS.success,
          html: undefined,
          confirmButtonColor: baseTheme.green.aside,
        });
        handleSelectCompany(user.user_metadata.accountID);
      } else {
        if (!hasSubmittedTeam && errorMessages !== undefined && errorMessages.code === 'submitInviteTeam') {
          Swal.close();
          Swal.fire({
            title: '',
            text: `It appears some of these users have already been invited to an Account.`,
            type: 'error',
            html: undefined,
            confirmButtonColor: baseTheme.red.error,
          });
        }
      }
    }
  }, [isLoading, errorMessages, hasSubmittedTeam])

  const handleFileImport = (users: UserType[]) => {

    let updatedDataSource: any = { ...state.accountTeam };

    const updatedCount = getList(updatedDataSource).length;

    for (let i = 0; i < users.length; i++) {
      updatedDataSource = {
        ...updatedDataSource,
        [updatedCount + i]: {
          key: updatedCount + i,
          ...users[i],
        },
      };
    }
    let checkedTeam: any = getEmployeesValidated(getList(updatedDataSource));

    const accountTeam = arrayToObject(checkedTeam, 'key');
    setIsRemoving(false);
    dispatch({ type: 'ADD_FILE_USERS', users: accountTeam, usersCounter: checkedTeam.length });

    checkedTeam.map((item: any) => (
      validateSeats(getList(state.accountTeam), item.package)
    ))
  };

  const handleAddTableItem = () => {
    const newItemIndex = state.teamCachedCounter;
    setIsRemoving(false);
    const newUser = {
      key: newItemIndex,
      givenName: '',
      familyName: '',
      email: '',
      jobTitle: '',
      name: '',
      reportsToUserID: user.user_id, //TODO: a quien se le va a asignar en reportsto si es gregado dsd admin
      contractorCompanyID: '0',
      canCreateProject: true,
      app_metadata: {
        role: {
          roleID: 1,
          name: ROLE[1],
        },
      },
      package: undefined
    };

    dispatch({ type: 'ADD_USER', user: newUser });

  };

  const handleRemoveTableItem = (key: number) => {
    dispatch({ type: 'REMOVE_USER', key });
    setIsRemoving(true);
  };

  const handleSelectCompany = (accountID: number) => {
    dispatch({ type: 'CLEAN_USERS' })
    dispatch({ type: 'SELECT_COMPANY', accountID });
    fetchSeatsInfo(accountID);
    fetchAccountUsersInfo(accountID);
  };


  const handleSubmit = () => {

    submitInviteTeamAction({
      team: state.accountTeam,
      accountID: user.user_metadata.accountID,
    });
  };

  const handleUpdateForm = (targetKey: string, value: any, key: string | number) => {

    const name = `${state.accountTeam[key].givenName} ${state.accountTeam[key].familyName}`;

    const selectedPackage = targetKey == 'package' ? value : undefined;


    if (targetKey != 'roleID') {
      let updatedUser = {
        ...state.accountTeam[key],
        [targetKey]: value,
        name,
      };

      dispatch({ type: 'UPDATE_USER', user: updatedUser, key });
    }

    if (targetKey == 'roleID') {
      let roles: Array<RoleType> = [
        { roleID: 1, name: 'User' },
        { roleID: 2, name: 'Administrator' }
      ];

      let updatedValue = { ...state.accountTeam[key] };

      updatedValue.app_metadata.role = roles.find(r => r.name === value);

      let updatedUser = {
        ...state.accountTeam[key],
        ...updatedValue,
        name,
      };

      dispatch({ type: 'UPDATE_USER', user: updatedUser, key });
    }

    validateSeats(getList(state.accountTeam), selectedPackage)
  };

  const validateSeats = (users: UserType[], selectedPackage: string | undefined) => {

    const selectedLicenceType = seatsInformation.find((p: PackageSeatDetail) => p.packageID === selectedPackage);

    if (!selectedLicenceType || state.selectedCompany == null)
      return false;

    var availableSeats = selectedLicenceType.availableSeats; //selectedCompany.noLicenses - selectedCompany.usedSeats;

    var registredUsers = users.filter(u => u.package == selectedLicenceType.packageID).length + 1;

    var pass = availableSeats > 0 && (availableSeats <= 0 || registredUsers <= availableSeats);

    if (!pass && !isRemoving) {
      Swal.close();
      Swal.fire({
        title: '',
        type: 'error',
        html: `<div>You are attempting to INVITE more users to your account than available licenses.</div> <br /> <div>Users Added : ` + registredUsers + ` , <strong>` + selectedPackage + ` Licenses</strong> : ` + availableSeats + `.</div> <br />Change the number of Inviting users or contact us at (<a href="mailto:sales@velocityvue.com">sales@velocityvue.com</a>) to add additional Licenses.`,
        confirmButtonColor: baseTheme.red.error,
      });
    }
    setIsValidSeat(pass);
    return pass;
  }

  const isValidSubmitTeam =
    validateTeam(getList(state.accountTeam)) &&
    state.selectedCompany !== undefined &&
    !Number.isNaN(state.selectedCompany)
    && isValidSeat;

  return (
    <InviteTeamComponent
      user={user}
      setContractorModal={setContractorModal}
      viewContractorModal={viewContractorModal}
      companyContractors={companyContractors}
      packages={packages}
      seatsDetails={seatsDetails}
      company={state.selectedCompany}
      isSubmittingTeam={isLoading}
      dataSource={getList(state.accountTeam)}
      handleRemoveTableItem={handleRemoveTableItem}
      handleAddTableItem={handleAddTableItem}
      handleFileImport={handleFileImport}
      handleSubmit={handleSubmit}
      handleSelectCompany={handleSelectCompany}
      handleUpdateForm={handleUpdateForm}
      validateTeam={isValidSubmitTeam}
      isSelectedAccount={state.selectedCompany}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  companyContractors: getAccountsContractors(state),
  companies: getCompanies(state),
  packages: getPackages(state),
  seatsInformation: getSeatsDetails(state),
  isLoading: state.admin.invite.inviteTeam.isLoading,
  hasSubmittedTeam: state.admin.invite.inviteTeam.hasSubmitedTeam,
  errorMessages: state.admin.invite.inviteTeam.errorMessages,
});

const mapDispatchToProps = {
  fetchCompaniesAction: fetchCompaniesAction.request,
  fetchContractorsAction: fetchContractorsAction.request,
  fetchPackagesAction: fetchPackagesAction.request,
  submitInviteTeamAction: submitInviteTeamAction.request,
  fetchSeatsInfo: fetchSeatsInfoAction.request,
  fetchAccountUsersInfo: fetchAccountUsersAction.request,
};

export const BackOfficeInviteTeamContainer = connect(mapStateToProps, mapDispatchToProps)(InviteTeam);
