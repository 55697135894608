import React, { FormEvent } from 'react';

import { TeamTableComponent } from '@app/admin/invite/invite-team/table/table.component';
import { ButtonControlComponent } from '@components/controls/button/button-control.component';
import { ViewPackageModal } from '@components/admin/view-package/view-package.component';

import { AccountType, UserType, ContractorType } from '@shared/types/backend.types';
import { FormattedMessage } from 'react-intl';
import { ThemeType } from '@shared/types/theme.type';
import { InviteTeamPasswordComponent } from '@components/admin/set-password/set-password.component';
import { UploadFileContainer } from '@components/bulk-team-upload/state/upload.container';

import { WrappedFormUtils } from 'antd/es/form/Form';

import './invite-team.styles.scss';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import { Classes } from 'jss';

export interface IAdminInviteTeam {
  accounts: AccountType[];
  tier1User: UserType | undefined;
  selectedCompanyInformation: AccountType;
  classes: Classes;
  companyContractors: ContractorType[];
  reportsToUsers: UserType[];
  seatsDetails: any;
  isSubmittingTeam: boolean;
  selectedAccountID?: number;
  user: UserType;
  dataSource: any;
  validateTeam: boolean;
  handleFileImport: (users: any) => void;
  handleAddTableItem: () => void;
  handleRemoveTableItem: (key: number) => void;
  handleSubmit: () => void;
  handleSelectCompany: (accountID: number) => void;
  savePasswordConfigration: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
  handleUpdateForm: (targetKey: string, value: any, key: string | number) => void;
}

const TeamInviteStyled = (props: IAdminInviteTeam) => {
  const {
    user,
    tier1User,
    classes,
    selectedCompanyInformation,
    companyContractors,
    accounts,
    reportsToUsers,
    selectedAccountID,
    seatsDetails,
    dataSource,
    validateTeam,
    isSubmittingTeam,
  } = props;

  const {
    savePasswordConfigration,
    handleAddTableItem,
    handleRemoveTableItem,
    handleFileImport,
    handleSubmit,
    handleUpdateForm,
  } = props;

  const { formTitleStyles, formMessageStyles } = classes;

  return (
    <div className="invite-team__container">
      <div className="sa__container">
        <div className="sa__box">
          <div className="sa__box-heading">
            <div>
              <h1 className={classnames(formTitleStyles, 'sa__box-heading-title')}>
                <FormattedMessage id="dashboard.user.invite.formTitle" />
              </h1>
              <h3 className={classnames(formMessageStyles, 'invite__form-message')}>
                <FormattedMessage id="dashboard.user.invite.formMessage" />
              </h3>
            </div>

            <div className="d-flex">
              <ViewPackageModal seatsDetails={seatsDetails} />
              <InviteTeamPasswordComponent savePasswordConfigration={savePasswordConfigration} />
            </div>
          </div>

          <div className="invite-team__table-container sa__box-content">
            <UploadFileContainer handleFileImport={handleFileImport} user={tier1User} />
            <TeamTableComponent
              user={user}
              accounts={accounts}
              selectedCompanyInformation={selectedCompanyInformation}
              selectedAccountID={selectedAccountID}
              companyContractors={companyContractors}
              packages={seatsDetails}
              reportsToUsers={reportsToUsers}
              users={dataSource}
              handleSort={() => console.log('handle sort')}
              handleUpdateForm={handleUpdateForm}
              handleAddTableItem={handleAddTableItem}
              handleRemoveTableItem={handleRemoveTableItem}
            />
            <ButtonControlComponent
              text={'Invite'}
              color="green"
              size="large"
              style={{ float: 'right', margin: '30px 0px', fontSize: 20 }}
              onClick={() => handleSubmit()}
              loading={isSubmittingTeam}
              disabled={!validateTeam}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  formTitleStyles: {
    color: theme.black,
  },

  selectStyles: {
    fontWeight: 'normal',
  },
});

export const InviteTeamComponent = injectSheet(styles)(TeamInviteStyled);
