import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactUsButton.css';
import { optionButtons, reportOptions } from '@shared/constants/assitant';
import {
  FETCH_PROJECT_FORMS_API_ALL,
  FETCH_ACCOUNTS_PROJECTS_API,
  FETCH_ACCOUNT_CA_API,
  FETCH_FORM_PDF_API,
  FETCH_PROJECT_FORMS_API,
  PTP_SUBMISSIONS_API,
  API_URL,
  POST_SCHEDULE_JOBS_API,
  FETCH_ACCOUNT_SUBMISSIONS,
  FETCH_ACCOUNTS_CONTRACTORS_API,
  FETCH_USER_TEAM_API,
} from '@constants/endpoints';
import Cookies from 'js-cookie';
import LoadingIndicator from './LoadingIndicator';
import { Console } from 'console';
import telegramIcon from './telegram.png';
import downarrow from './down.png';
import TimeDropdown from './TimeDropdown';
interface AddScheduleModalProps {
  openModal: boolean;
  onClose: () => void;
  // onCreate: (newJobDetails: any) => Promise<void>;
  user: any;
  onSubmit: () => void;
}
const ContactUsButton: React.FC<AddScheduleModalProps> = ({
  openModal,
  onClose,
  user,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('Create New Report');
  const [selectedBodyTxt, setBodyTxt] = useState<string>('Select Scheduled Report Type');
  const [viewState, setViewState] = useState<
    | 'initial'
    | 'reports'
    | 'projects'
    | 'safetyPlanList'
    | 'schedule'
    | 'nodata'
    | 'submissions'
    | 'success'
    | 'forms'
    | 'projectApi'
    | 'userScreen'
    | 'timeRange'
    | 'assignedUserList'
  >('initial');

  const [errors, setErrors] = useState({
    title: '',
    frequency: '',
    executionTime: '',
    description: '',
    recipientEmails: '',
    timezone: '',
  });

  console.log('user', user);

  const validateForm = () => {
    const emailPattern = /^([^\s@]+@[^\s@]+\.[^\s@]+)(,\s*[^\s@]+@[^\s@]+\.[^\s@]+)*$/;
    const newErrors = {
      title: jobDetails.title ? '' : ' Schedule Job title is required.',
      frequency: jobDetails.frequency ? '' : ' Frequency is required.',
      executionTime: jobDetails.executionTime ? '' : ' Execution time is required.',
      description: jobDetails.description ? '' : ' Description is required.',
      recipientEmails: emailPattern.test(jobDetails.recipientEmails)
        ? ''
        : ' Valid email(s) are required.',
      timezone: jobDetails.timezone_txt ? '' : 'Timezone is required',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === '');
  };
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [projectList, setProjectList] = useState<any[]>([]);
  const [submissionList, setSubmissionList] = useState<any[]>([]);
  const [formList, setFormList] = useState<any[]>([]); // Added form list state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;
  const [showFrequencyOptions, setShowFrequencyOptions] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<string | null>(null);
  const [submissionCount, setSubmissionCount] = useState<number | null>(null);
  const [terms, setTerms] = useState<string | null>(null);
  const [safetyPlanList, setSafetyPlanList] = useState<string[]>([]);
  const [selectedSafetyPlan, setSelectedSafetyPlan] = useState<any | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedState, setState] = useState<string>('');
  const [activityType, setactivityType] = useState<string>('');
  const [selectProjectId, setProjectId] = useState<string>('0');
  const [selectUser, setSelectedUserId] = useState<string>('0');
  const [selectProgram, setProgramname] = useState<any>('');
  const [selectFormLabel, setselectFormLabel] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedNames, setselectedNames] = useState<any[]>([]);
  const [selectedUsers, setselectedAsUsers] = useState<any[]>([]);
  const [selectedCorrective, setselectedCorrective] = useState<any[]>([]);
  const [AssignUser, setAssignUser] = useState<any[]>([]);
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const daysOfMonth = Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'));

  const timezones = [
    { value: 'America/Chicago', label: 'Central Time (Texas)' },
    { value: 'America/New_York', label: 'Eastern Time (US)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (US)' },
    { value: 'America/Denver', label: 'Mountain Time (US)' },
    { value: 'America/Phoenix', label: 'Arizona (no DST)' },
    { value: 'America/Anchorage', label: 'Alaska Time' },
    { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time' },
    { value: 'America/Toronto', label: 'Eastern Time (Canada)' },
    { value: 'America/Vancouver', label: 'Pacific Time (Canada)' },
    { value: 'America/Edmonton', label: 'Mountain Time (Canada)' },
    { value: 'America/Winnipeg', label: 'Central Time (Canada)' },
    { value: 'America/St_Johns', label: 'Newfoundland Time (Canada)' },
    { value: 'America/Halifax', label: 'Atlantic Time (Canada)' },
    { value: 'America/Regina', label: 'Central Time (Canada - Saskatchewan)' },
    { value: 'America/Iqaluit', label: 'Eastern Time (Canada - Nunavut)' },
    { value: 'America/Whitehorse', label: 'Pacific Time (Canada - Yukon)' },
    { value: 'America/Yellowknife', label: 'Mountain Time (Canada - Northwest Territories)' },
  ];

  const [jobDetails, setJobDetails] = useState({
    title: '',
    frequency: '',
    executionTime: '',
    description: '',
    recipientEmails: '',
    timezone_txt: 'America/Chicago',
    dayOfMonth: '',
    dayOfWeek: '',
  });

  const toggleMessageBox = () => {
    //setIsOpen(prev => !prev);
    //resetState();
    //setIsVisible(true);
  };

  const toggleMessageReset = () => {
    setselectFormLabel('');
    setIsVisible(true);
    resetState();
  };

  const toggleMessageBoxReset = () => {
    //  setIsVisible(true);
    openModal = false;
    setIsOpen(false);
    resetState();
  };

  const toggleUserSelection = (user: any) => {
    if (user === 'All') {
      if (selectedUsers.includes('All')) {
        setselectedAsUsers([]);
      } else {
        setselectedAsUsers(['All']);
      }
    } else {
      if (selectedUsers.includes('All')) {
        setselectedAsUsers(['']);
      } else {
        setselectedAsUsers(prevSelectedUsers =>
          prevSelectedUsers.includes(user)
            ? prevSelectedUsers.filter(u => u !== user)
            : [...prevSelectedUsers, user],
        );
      }
    }
  };

  const toggleMessageTxt = () => {
    setIsVisible(true);
    setIsVisible(false);
  };

  useEffect(() => {
    if (openModal) {
      setIsOpen(openModal);
      resetState();
      setselectFormLabel('');
    }
  }, [openModal]);

  const resetState = () => {
    setSelectedOption('Create New Report');
    setBodyTxt('Select Scheduled Report Type');
    setViewState('initial');
    setProjectList([]);
    setSubmissionList([]);
    setFormList([]);
  };

  const Admin_Api_Key = user.user_metadata.acAPIKey;

  const handleOptionClick = async (optionLabel: string) => {
    setTerms(optionLabel);
    setState(optionLabel);
    if (optionLabel === 'Reports') {
      setViewState('reports');
      setSelectedOption('Reports');
      setBodyTxt('Could you please specify what type of report you need?');
    } else if (optionLabel === 'Submissions Report') {
      setSelectedOption('Submissions Report');
      setactivityType('submissions_report');
      await fetchSubmissions();
    } else if (optionLabel === 'Corrective Actions Report') {
      setSelectedOption('Corrective Actions Report');
      setactivityType('ca_report');
      await fetchProjects();
    } else if (optionLabel === 'Pre Task Plans Report') {
      setSelectedOption('Pre Task Plans Report');
      setactivityType('ptp_submissions_report');
      await fetchProjects();
    } else if (optionLabel === 'Projects') {
      setSelectedOption('Projects Report');
      setactivityType('projects_report');
      await fetchProjectsApi();
    } else if (optionLabel === 'Contractor Companies') {
      setSelectedOption('Contractor Companies Report');
      setactivityType('cc_report');
      await fetchContractor();
    } else if (optionLabel === 'Associates') {
      setSelectedOption('Associates Report');
      setactivityType('associates_report');
      await fetchAssosiates();
    } else {
      // setSelectedOption(optionLabel);
    }
  };

  const fetchProjectsApi = async () => {
    if (!user) return;

    const endpoint = FETCH_ACCOUNTS_PROJECTS_API.replace(
      ':accountID',
      `${user.user_metadata.accountID}`,
    );

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      const projects = response.data;

      setSubmissionCount(projects.length);
      //setFormList(filteredForms);
      setViewState('forms');
      setBodyTxt('');
    } catch (error) {
      console.error('Error fetching projects:', error);
      setSelectedOption('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContractor = async () => {
    if (!user) return;

    const endpoint = FETCH_ACCOUNTS_CONTRACTORS_API.replace(
      ':accountID',
      `${user.user_metadata.accountID}`,
    );

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      const projects = response.data;

      setSubmissionCount(projects.length);
      //setFormList(filteredForms);
      setViewState('forms');
      setBodyTxt('');
    } catch (error) {
      console.error('Error fetching projects:', error);
      setSelectedOption('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssosiates = async () => {
    if (!user) return;

    const endpoint = FETCH_USER_TEAM_API.replace(':userID', `${user.user_id}`);

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      const projects = response.data;

      setSubmissionCount(projects.length);
      //setFormList(filteredForms);
      setViewState('forms');

      setBodyTxt('');
    } catch (error) {
      console.error('Error fetching projects:', error);
      setSelectedOption('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubmissions = async () => {
    if (!user) return;

    const endpoint = FETCH_PROJECT_FORMS_API_ALL.replace(
      ':accountID',
      `${user.user_metadata.accountID}`,
    );

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });

      const projectMap = new Map();

      response.data.forEach((item: any) => {
        const { projectID, name } = item.project;
        if (!projectMap.has(projectID)) {
          projectMap.set(projectID, { projectID, name });
        }
      });

      setSubmissionList(response.data);
      const projectList = Array.from(projectMap.values());
      const projects = projectList;

      const projectsWithAll = [{ projectID: '0', name: 'All' }, ...projects];
      setProjectList(projectsWithAll);
      setViewState('projects');

      setBodyTxt('Please Select Project');
    } catch (error) {
      console.error('Error fetching projects:', error);
      setSelectedOption('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = async () => {
    if (!user) return;

    const endpoint = FETCH_ACCOUNTS_PROJECTS_API.replace(
      ':accountID',
      `${user.user_metadata.accountID}`,
    );

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });

      setSubmissionList(response.data);

      const projects = response.data;

      const projectsWithAll = [{ projectID: '0', name: 'All' }, ...projects];
      setProjectList(projectsWithAll);
      setViewState('projects');

      setBodyTxt('Please Select Project');
    } catch (error) {
      console.error('Error fetching projects:', error);
      setSelectedOption('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFormsForProject = async (projectID: any) => {
    if (!user) return;

    setIsLoading(true);

    try {
      // If '0' is in projectID, show all safety programs
      const isAllProjectsSelected = projectID.includes('0');

      // Filter by projectID unless '0' is selected
      const filteredSubmissions = isAllProjectsSelected
        ? submissionList // Show all submissions if '0' is selected
        : submissionList.filter(item => projectID.includes(item.project.projectID));

      const safetyPlanNames = Array.from(
        new Set(filteredSubmissions.map(item => item.safetyPlanName)),
      );

      // Add 'All' to safety plans if it's not an 'All Projects' scenario
      const safetyPlansWithAll =
        isAllProjectsSelected || safetyPlanNames.length > 0
          ? ['All', ...safetyPlanNames]
          : safetyPlanNames;

      setBodyTxt('Please Select Program');
      console.log('safetyPlans', safetyPlansWithAll);
      setSafetyPlanList(safetyPlansWithAll);

      if (safetyPlanNames.length === 0) {
        setFormList(filteredSubmissions); // Display all if no safety plans
        setViewState('nodata');
        setBodyTxt('');
      } else {
        setFormList(filteredSubmissions);
        setViewState('safetyPlanList');
      }
    } catch (error) {
      setSelectedOption('No Submissions:');
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextSafety = () => {
    setSelectedSafetyPlan(selectedNames);

    const numbers = selectedNames;
    const result = numbers.join('^~^');
    console.log('result', result);
    setSelectedSafetyPlan(result);

    let filteredForms = [];

    if (selectedNames.includes('All')) {
      filteredForms = formList;
    } else {
      filteredForms = formList.filter(item => selectedNames.includes(item.safetyPlanName));
    }

    setSubmissionCount(filteredForms.length);
    setFormList(filteredForms);
    setBodyTxt('Select User');
    setViewState('userScreen');
    setProgramname(selectedNames);
  };

  const filterFormsBySafetyPlan = (safetyPlanName: string) => {
    setSelectedSafetyPlan(safetyPlanName);
    let filteredForms: any[] = [];
    if (safetyPlanName === 'All') {
      filteredForms = formList;
    } else {
      filteredForms = formList.filter((item: any) => item.safetyPlanName === safetyPlanName);
    }

    setSubmissionCount(filteredForms.length);
    setFormList(filteredForms);
    //setViewState('forms');

    setBodyTxt('Select User');
    setViewState('userScreen');
    setProgramname(safetyPlanName);
  };

  const fetchCAList = async (projectID: string) => {
    console.log('tessstttt', projectID);
    if (!user) return;

    const endpoint = FETCH_ACCOUNT_CA_API.replace(':accountID', `${user.user_metadata.accountID}`);

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      console.log(response);

      const projectIDArray = projectID.split('^~^').map(id => Number(id));
      console.log('projectIDArray', projectIDArray);
      const filteredData = response.data.filter((item: { projectID: any }) =>
        projectIDArray.includes(item.projectID),
      );

      const assignedToUserArray = [
        'All',
        ...Array.from(new Set(response.data.map((item: any) => item.assignedToUser))),
      ];
      console.log('assignedToUserArray', assignedToUserArray);

      setAssignUser(assignedToUserArray);
      setSubmissionCount(filteredData.length);
      setselectedCorrective(filteredData);
      setBodyTxt('Select Assigned User');
      setViewState('assignedUserList');
    } catch (error) {
      setSelectedOption('No Submissions:');
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPtpList = async (projectID: string) => {
    if (!user) return;

    const endpoint = PTP_SUBMISSIONS_API;
    console.log('projectId:', projectID);
    setIsLoading(true);

    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });
      const dataArray = response.data.data;
      console.log('dataArray:', dataArray);
      const projectIDArray = projectID.split('^~^').map(id => (id === '0' ? id : Number(id)));
     

      let filteredData = [];

      const showAllSubmissions = projectIDArray.includes(0);

      if (showAllSubmissions) {
        filteredData = dataArray;
      } else {
        filteredData = dataArray.filter((item: any) =>
          projectIDArray.includes(item.ptp.project.projectID),
        );
      }

      setSubmissionCount(filteredData.length);
      setBodyTxt('');
      setViewState('forms');
    } catch (error) {
      setSelectedOption('No Submissions:');
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubmissionReportsPdf = async (submissionId: string) => {
    if (!user || !submissionId) return;

    const endpoint = FETCH_FORM_PDF_API.replace(':submissionID', submissionId);

    setIsLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: tokenKey },
      });

      const pdfUrl = response.data.pdfLocation;
      window.open(pdfUrl, '_blank');

      setSelectedOption('PDF downloaded successfully.');
    } catch (error) {
      console.error('Error fetching submission PDF:', error);
      setSelectedOption('Failed to load submission PDF. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleProjectClick = (project: any) => {
    setBodyTxt('');
    setProjectId(project.projectID);
    if (terms == 'Submissions Report') {
      fetchFormsForProject(project.projectID);
    } else if (terms == 'Corrective Actions Report') {
      fetchCAList(project.projectID);
    } else {
      getPtpList(project.projectID);
    }
  };

  const handleSubmissionClick = (submission: any) => {
    fetchSubmissionReportsPdf(submission.id);
    console.log(submission);
  };

  const handleFrequencyChange = () => {
    console.log('HELLO');
  };

  const handleScheduleSubmit = () => {
    console.log('HELLO');
  };

  const handleNextUser = (user_id: any) => {
    setViewState('timeRange');

    setBodyTxt('');
  };

  const handleScheduleConfirmation = (isScheduled: boolean) => {
    if (isScheduled) {
      setViewState('schedule');
      setselectFormLabel('Schedule New Report');
      //setSelectedOption('');
    } else {
      console.log('No scheduling chosen.');
    }
  };

  const goBack = () => {
    setIsVisible(true);
    setSelectedItems([]);
    setselectedNames([]);
    setJobDetails({
      title: '',
      frequency: '',
      executionTime: '',
      description: '',
      recipientEmails: '',
      timezone_txt: '',
      dayOfWeek: '',
      dayOfMonth: '',
    });
    setErrors({
      title: '',
      frequency: '',
      executionTime: '',
      description: '',
      recipientEmails: '',
      timezone: '',
    });
    if (viewState === 'forms') {
      setselectFormLabel('');
      if (selectedState === 'Submissions Report') {
        setselectFormLabel('');
        setViewState('projects');
        setBodyTxt('Please Select Project');
        setFormList([]);
      } else if (selectedState === 'Corrective Actions Report') {
        setselectFormLabel('');
        setViewState('projects');
        setBodyTxt('Please Select Project');
        setFormList([]);
      } else if (selectedState === 'Pre Task Plans Report') {
        setselectFormLabel('');
        setViewState('projects');
        setBodyTxt('Please Select Project');
        setFormList([]);
      } else if (selectedState === 'Associates') {
        setselectFormLabel('');
        setViewState('initial');
        setSelectedOption('Create New Report');
        setBodyTxt('Select Scheduled Report Type');
      } else if (selectedState === 'Contractor Companies') {
        setselectFormLabel('');
        setViewState('initial');
        setSelectedOption('Create New Report');
        setBodyTxt('Select Scheduled Report Type');
      } else if (selectedState === 'Projects') {
        setselectFormLabel('');
        setViewState('initial');
        setSelectedOption('Create New Report');
        setBodyTxt('Select Scheduled Report Type');
      }
    } else if (viewState === 'projects') {
      setselectFormLabel('');
      setViewState('reports');
      setSelectedOption('Reports');
      setBodyTxt('Could you please specify what type of report you need?');
      setProjectList([]);
    } else if (viewState === 'reports') {
      setselectFormLabel('');
      setViewState('initial');
      setSelectedOption('Create New Report');
      setBodyTxt('Select Scheduled Report Type');
    } else if (viewState === 'safetyPlanList') {
      setselectFormLabel('');
      setViewState('projects');
      //setSelectedOption('Project');
      setBodyTxt('Please Select Project');
    } else if (viewState === 'schedule') {
      setViewState('forms');
      setselectFormLabel('');
      setBodyTxt('');
      setFormList([]);
    } else if (viewState === 'nodata') {
      setViewState('projects');
      //setSelectedOption('Project');
      setBodyTxt('Please Select Project');
      setselectFormLabel('');
      setFormList([]);
    } else if (viewState === 'projectApi') {
      setViewState('initial');
      setSelectedOption('Create New Report');
      setBodyTxt('Select Scheduled Report Type');
    } else if (viewState === 'userScreen') {
    } else if (viewState === 'timeRange') {
      setselectFormLabel('');
      setViewState('userScreen');
      setBodyTxt('Select User');
    }
  };

  const handleTimeChange = (time: string) => {
    setJobDetails(prevDetails => ({
      ...prevDetails,
      executionTime: time,
    }));
  };

  const updateJobDetail = (key: string, value: string) => {
    setJobDetails(prev => ({ ...prev, [key]: value }));
  };

  const handleScheduleJobSubmit = async () => {
    if (!validateForm()) return;
    if (jobDetails.executionTime === 'Select time') {
      errors.executionTime = 'Execution time is required.'; // Set the error message
    }
    setIsLoading(true);

    const endpoint = POST_SCHEDULE_JOBS_API.replace(':id', `${user.user_metadata.accountID}`);
    let jobFrequencyValue = '0';
    if (jobDetails.frequency === 'daily') {
      jobFrequencyValue = '0';
    } else if (jobDetails.frequency === 'weekly') {
      jobFrequencyValue = jobDetails.dayOfWeek;
    } else if (jobDetails.frequency === 'monthly') {
      jobFrequencyValue = jobDetails.dayOfMonth;
    }
    const requestBody = [
      {
        accountId: user.user_metadata.accountID,
        userId: user.user_id,
        activityType: activityType,
        projectIds: selectProjectId,
        safetyPrograms: selectedSafetyPlan,
        userIds: '',
        jobTitle: jobDetails.title,
        jobDescription: jobDetails.description,
        jobFrequency: jobDetails.frequency,
        jobFrequencyValue: jobFrequencyValue,
        jobTime: jobDetails.executionTime,
        jobTimezone: jobDetails.timezone_txt,
        recipientEmails: jobDetails.recipientEmails,
        status: 'active',
      },
    ];

    console.log(requestBody);
    try {
      await axios.post(endpoint, requestBody, {
        headers: { Authorization: tokenKey },
      });

      setSelectedOption('Success');
      setViewState('success');
      setJobDetails({
        title: '',
        frequency: '',
        executionTime: '',
        description: '',
        recipientEmails: '',
        timezone_txt: '',
        dayOfMonth: '',
        dayOfWeek: '',
      });
    } catch (error) {
      console.error('Error scheduling report:', error);
      setSelectedOption('Failed to schedule report. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleItemSelection = (id: any) => {
    console.log('idddd', id);
    if (id === 0) {
      setSelectedItems(prevSelectedItems => (prevSelectedItems.includes(0) ? [] : [0]));
    } else {
      setSelectedItems(prevSelectedItems =>
        prevSelectedItems.includes(id)
          ? prevSelectedItems.filter(itemId => itemId !== id)
          : [...prevSelectedItems, id],
      );
    }
  };

  const toggleNameSelection = (name: string) => {
    console.log('name-->', name);
    
    if (name === 'All') {
      setselectedNames(prevSelectedItems => 
        prevSelectedItems.includes('All') ? [] : ['All']
      );
    } else {
      setselectedNames(prevSelectedItems => {
        // If "All" is already selected, deselect it first
        if (prevSelectedItems.includes('All')) {
          return [name];
        }
        // Otherwise, add or remove the clicked name as usual
        return prevSelectedItems.includes(name)
          ? prevSelectedItems.filter(item => item !== name)
          : [...prevSelectedItems, name];
      });
    }
  };

  const handleNext = () => {
    // If '0' is in selectedItems, set result to an empty string
    const result = selectedItems.includes('0') ? '' : selectedItems.join('^~^');

    console.log('result', result);
    setBodyTxt('');
    setProjectId(result);

    // Handle different terms
    if (terms === 'Submissions Report') {
      fetchFormsForProject(selectedItems);
    } else if (terms === 'Corrective Actions Report') {
      fetchCAList(result);
    } else {
      getPtpList(result);
    }
  };

  const handleNextAssignedUser = () => {
    let filteredForms = [];

    console.log('selectedUsers', selectedUsers);
    console.log('selectedCorrective', selectedCorrective);
    if (selectedUsers.includes('')) {
      filteredForms = selectedCorrective;
    } else {
      filteredForms = selectedCorrective.filter(item =>
        selectedCorrective.includes(item.assignedToUser),
      );
    }

    setSubmissionCount(filteredForms.length);
    setBodyTxt('');
    setViewState('forms');
  };

  console.log('project--list', selectedItems);

  const openReport = () => {
    let safetyPlanName = selectedSafetyPlan;
    if (selectedSafetyPlan === 'All') {
      safetyPlanName = '';
    }
    const reportUrl = API_URL;
    if (selectedState === 'Submissions Report') {
      setactivityType('submissions_report');
      const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/submissions-export?userId=' +
        user.user_id +
        'projectIds=' +
        selectProjectId +
        '&safetyPrograms=' +
        safetyPlanName;

      window.open(url, '_blank');
    } else if (selectedState === 'Corrective Actions Report') {
      setactivityType('ca_report');
      const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/ca-export?userId=' +
        user.user_id +
        '&projectIds=' +
        selectProjectId;

      window.open(url, '_blank');
    } else if (selectedState === 'Pre Task Plans Report') {
      setactivityType('ptp_submissions_report');
      const url =
        reportUrl +
        '/reports/' +
        Admin_Api_Key +
        '/ptp-submissions-export?projectIds=' +
        selectProjectId;

      window.open(url, '_blank');
    } else if (selectedState === 'Contractor Companies') {
      setactivityType('cc_report');
      const url = reportUrl + '/reports/' + Admin_Api_Key + '/cc-export?userID=' + user.user_id;

      window.open(url, '_blank');
    } else if (selectedState === 'Projects') {
      setactivityType('projects_report');
      const url =
        reportUrl + '/reports/' + Admin_Api_Key + '/projects-export?userID=' + user.user_id;

      window.open(url, '_blank');
    } else if (selectedState === 'Associates') {
      setactivityType('associates_report');
      const url =
        reportUrl + '/reports/' + Admin_Api_Key + '/associates-export?userID=' + user.user_id;

      window.open(url, '_blank');
    } else {
      setselectFormLabel('');
    }
  };

  const handleTimeRangeChange = (daysRange: any) => {
    console.log('Selected Time Range:', daysRange);
    const days = parseInt(daysRange, 10);
    //
    const now: any = new Date();
    console.log('projectList', selectedItems);
    console.log('safetyPlanList', selectProgram);
    console.log('selectedValue', daysRange);
    console.log('submissionList', submissionList);

    const filteredSubmissions = submissionList.filter(item => {
      const showAllSubmissions = selectedItems.includes('0') && selectProgram.includes('All');

      if (showAllSubmissions) {
        return true;
      } else {
        const projectMatch =
          selectedItems.includes('0') || selectedItems.includes(item.project.projectID);
        const safetyPlanMatch =
          selectProgram.includes('All') || selectProgram.includes(item.safetyPlanName);

        if (daysRange === '0') {
          return projectMatch && safetyPlanMatch;
        } else {
          const startDate = new Date();
          startDate.setDate(now.getDate() - daysRange);
          const creationDateTime = new Date(item.creationDateTime);
          const dateMatch = creationDateTime >= startDate && creationDateTime <= now;
          return projectMatch && safetyPlanMatch && dateMatch;
        }
      }
    });
    console.log('count ----->', filteredSubmissions.length);

    setSubmissionCount(filteredSubmissions.length);
    setViewState('forms');

    setBodyTxt('');
  };

  return (
    <>
      {/* {isOpen ? (
        <div className="floating-contact-button" onClick={toggleMessageBox}>
          <img src={downarrow} alt="Contact Us" className="contact-icon" />
        </div>
      ) : (
        <div className="floating-contact-button" onClick={toggleMessageBox}>
          <img src={telegramIcon} alt="Contact Us" className="contact-icon" />
        </div>
      )} */}

      {openModal && (
        <div className="modal-overlay">
          <div className="slide-up-container">
            <div className="message-box">
              <div className="title-bar">
                {viewState !== 'initial' ? (
                  <div>
                    <button className="back-button" onClick={goBack}>
                      ←
                    </button>
                    <h1 className="txt_initial">
                      {!selectFormLabel ? selectedOption : selectFormLabel}
                    </h1>
                  </div>
                ) : (
                  <div>
                    <h1 className="initial">{selectedOption}</h1>
                  </div>
                )}

                <button className="cancel-button" onClick={onClose}>
                  x
                </button>
              </div>
              <hr />
              <div className="button-group">
                {selectedBodyTxt && <p className="initialDesc">{selectedBodyTxt}</p>}
                {isLoading ? (
                  <div>
                    <LoadingIndicator />
                  </div>
                ) : viewState === 'reports' ? (
                  reportOptions.map(option => (
                    <button
                      key={option.id}
                      className="option-button"
                      onClick={() => handleOptionClick(option.label)}
                    >
                      <span>{option.label}</span>
                      <span className="arrow">→</span>
                    </button>
                  ))
                ) : viewState === 'projects' ? (
                  <div>
                    {projectList.map(project => (
                      <button
                        key={project.projectID}
                        className="option-button"
                        //onClick={() => handleProjectClick(project)}
                      >
                        <span>{project.name}</span>
                        <input
                          type="checkbox"
                          id={`report-${project.projectID}`}
                          checked={selectedItems.includes(project.projectID)}
                          onChange={() => toggleItemSelection(project.projectID)}
                          disabled={selectedItems.includes('0') && project.projectID !== '0'}
                        />
                      </button>
                    ))}

                    <div className="footer-buttons">
                      <button className="cancel-frm-button" onClick={onClose}>
                        Cancel
                      </button>
                      <button
                        className="next-button"
                        onClick={handleNext}
                        disabled={selectedItems.length === 0}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : viewState === 'success' ? (
                  <div>
                    <h2 className="success">Job scheduled successfully!</h2>
                    <button className="home-button" onClick={toggleMessageReset}>
                      Create New Report
                    </button>
                  </div>
                ): viewState === 'userScreen' ? (
                  <div className="user-container">
                    <div className="user-list">
                      <button key={0} className="option-button">
                        <span>ALL</span>
                        <input
                          type="checkbox"
                          checked={selectedNames.includes('ALL')}
                          onChange={() => toggleNameSelection('ALL')}
                        />
                      </button>
                      
                      <button key={user.id} className="option-button">
                        <span>{user.name}</span>
                        <input
                          type="checkbox"
                          checked={selectedNames.includes(user.name)}
                          onChange={() => toggleNameSelection(user.name)}
                        />
                      </button>
                    </div>
                    
                    <div className="footer-buttons">
                      <button className="cancel-frm-button" onClick={onClose}>
                        Cancel
                      </button>
                      <button
                        className="next-button"
                        onClick={handleNextUser}
                        disabled={selectedNames.length === 0}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : viewState === 'timeRange' ? (
                  <div className="time-range-container">
                    <label htmlFor="timeRangeDropdown" className="time-range-label">
                      Select Time Range:
                    </label>
                    <div className="dropdown-wrapper">
                      <select
                        id="timeRangeDropdown"
                        onChange={e => handleTimeRangeChange(e.target.value)}
                        defaultValue=""
                        className="custom-dropdown"
                      >
                        <option value="" disabled>
                          Select a range
                        </option>
                        <option value="0">All Days</option>
                        <option value="7">7 Days</option>
                        <option value="30">30 Days</option>
                        <option value="60">60 Days</option>
                        <option value="90">90 Days</option>
                        <option value="120">120 Days</option>
                      </select>
                      <span className="dropdown-arrow">▼</span>
                    </div>
                  </div>
                ) : viewState === 'safetyPlanList' ? (
                  <div className="safety-plan-container">
                    <div className="safety-plan-list">
                      {safetyPlanList.map((name, index) => (
                        <button
                          key={index}
                          className="option-button"
                          //onClick={() => filterFormsBySafetyPlan(name)}
                        >
                          <span>{name || ''}</span>
                          <input
                            type="checkbox"
                            id={`report-${name}`}
                            checked={selectedNames.includes(name)}
                            onChange={() => toggleNameSelection(name)}
                            disabled={selectedItems.includes('All') && name !== 'All'}
                          />
                        </button>
                      ))}
                    </div>
                    <div className="footer-buttons">
                      <button className="cancel-frm-button" onClick={onClose}>
                        Cancel
                      </button>
                      <button
                        className="next-button"
                        onClick={handleNextSafety}
                        disabled={selectedNames.length === 0}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : viewState === 'assignedUserList' ? (
                  <div className="assigned-user-container">
                    <div className="assigned-user-list">
                      {AssignUser.map((user, index) => (
                        <button
                          key={index}
                          className="option-button"
                          // onClick={() => filterFormsByAssignedUser(user)} // Optional: add filter logic if needed
                        >
                          <span>{user || ''}</span>
                          <input
                            type="checkbox"
                            id={`report-${user}`}
                            checked={selectedUsers.includes(user)}
                            onChange={() => toggleUserSelection(user)}
                          />
                        </button>
                      ))}
                    </div>
                    <div className="footer-buttons">
                      <button className="cancel-frm-button" onClick={onClose}>
                        Cancel
                      </button>
                      <button
                        className="next-button"
                        onClick={handleNextAssignedUser}
                        disabled={selectedUsers.length === 0}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : viewState === 'nodata' ? (
                  <div>
                    <h3>No Programs Found in this project please choose another one..</h3>
                  </div>
                ) : viewState === 'schedule' ? (
                  <form className="job-schedule-form">
                    <label className="from-label">Scheduled Report Title</label>
                    <input
                      type="text"
                      placeholder="Enter Title"
                      value={jobDetails.title}
                      onChange={e => updateJobDetail('title', e.target.value)}
                      required
                    />
                    {errors.title && <span className="error">{errors.title}</span>}

                    <label className="from-label">Report Frequency</label>
                    <select
                      value={jobDetails.frequency}
                      onChange={e => updateJobDetail('frequency', e.target.value)}
                      required
                    >
                      <option value="" disabled>
                        Select Frequency
                      </option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                    {errors.frequency && <span className="error">{errors.frequency}</span>}

                    {jobDetails.frequency === 'monthly' && (
                      <>
                        <label className="from-label">Select Day of Month</label>
                        <select
                          value={jobDetails.dayOfMonth}
                          onChange={e => updateJobDetail('dayOfMonth', e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select Date
                          </option>
                          {daysOfMonth.map(day => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          ))}
                        </select>
                      </>
                    )}

                    {jobDetails.frequency === 'weekly' && (
                      <>
                        <label className="from-label">Select Day of Week</label>
                        <select
                          value={jobDetails.dayOfWeek}
                          onChange={e => updateJobDetail('dayOfWeek', e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select Day
                          </option>
                          {daysOfWeek.map(day => (
                            <option key={day} value={day.toLowerCase()}>
                              {day}
                            </option>
                          ))}
                        </select>
                      </>
                    )}

                    <label className="from-label">Timezone</label>
                    <select
                      value={jobDetails.timezone_txt}
                      onChange={e => updateJobDetail('timezone_txt', e.target.value)}
                    >
                      {timezones.map(tz => (
                        <option key={tz.value} value={tz.value}>
                          {tz.label}
                        </option>
                      ))}
                    </select>
                    {errors.timezone && <span className="error">{errors.timezone}</span>}

                    <label className="from-label">Report Execution Time</label>
                    <TimeDropdown onTimeChange={handleTimeChange} />
                    {errors.executionTime && <span className="error">{errors.executionTime}</span>}

                    <label className="from-label">Description</label>
                    <textarea
                      placeholder="Enter Description"
                      value={jobDetails.description}
                      onChange={e => updateJobDetail('description', e.target.value)}
                      required
                    />
                    {errors.description && <span className="error">{errors.description}</span>}

                    <label className="from-label">Recipient Email(s)</label>
                    <textarea
                      placeholder="Recipient Email(s)"
                      value={jobDetails.recipientEmails}
                      onChange={e => updateJobDetail('recipientEmails', e.target.value)}
                      required
                    />
                    <span className="txt_comma">
                      Add multiple email addresses separated by comma (,)
                    </span>
                    {errors.recipientEmails && (
                      <span className="error">{errors.recipientEmails}</span>
                    )}

                    <button
                      type="button"
                      onClick={handleScheduleJobSubmit}
                      className="submit-button"
                    >
                      Submit
                    </button>
                  </form>
                ) : viewState === 'forms' ? (
                  <>
                    <div className="countHead">
                      <h4>
                        Total {selectedOption.replace('Report', '').trim()}: {submissionCount}
                      </h4>
                    </div>
                    {submissionCount && submissionCount > 0 ? (
                      <h4 className="countHead">
                        Your report is ready.{' '}
                        <a href="#" onClick={openReport}>
                          Click here
                        </a>{' '}
                        to view it.
                      </h4>
                    ) : (
                      ''
                    )}

                    {isVisible && (
                      <div className="radio-group">
                        <hr />
                        <p className="form_txt">
                          Do you want to schedule this job to receive reports on a regular basis?
                        </p>
                        <div className="button-next-group">
                          <button
                            className="yes-button option-next-button"
                            onClick={() => handleScheduleConfirmation(true)}
                          >
                            Yes
                          </button>
                          <button
                            className="no-button option-next-button"
                            onClick={toggleMessageTxt}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}
                    {showFrequencyOptions && (
                      <>
                        <div className="frequency-select">
                          <label>
                            Select Frequency:
                            <select value={frequency || ''} onChange={handleFrequencyChange}>
                              <option value="" disabled>
                                Select...
                              </option>
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                          </label>
                        </div>
                        <button className="submit-button" onClick={handleScheduleSubmit}>
                          Submit
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  optionButtons.map(option => (
                    <button
                      key={option.id}
                      className="option-button"
                      onClick={() => handleOptionClick(option.label)}
                    >
                      <span>{option.label}</span>
                      <span className="arrow">→</span>
                    </button>
                  ))
                )}
              </div>

              {/* <button className="close-button" onClick={toggleMessageBox}>
              Close
            </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsButton;
