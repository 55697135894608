import { BASE_URL } from '@services/axios/axios.config';

export const API_URL = BASE_URL;

//TODO: refactor API variables to reuse API route
//account route
export const ACCOUNTS_API = `${BASE_URL}/accounts`;

// *** SYS ADMI ***
export const FETCH_ACCOUNTS_API = `${BASE_URL}/accounts`;
export const ADD_COMPANY_ACCOUNT_API = `${BASE_URL}/accounts`;
export const UPDATE_COMPANY_ACCOUNT_API = `${BASE_URL}/accounts`;
export const FETCH_ACCOUNT_IS_CONFIGURATE = `${BASE_URL}/accounts/:accountID`;
export const FETCH_ACCOUNT_SUBMISSIONS = `${BASE_URL}/accounts/:accountID/submissions`;
export const PATCH_ACCOUNT_INFO = `${BASE_URL}/accounts/:accountID`;
export const FETCH_PACKAGE_API = `${BASE_URL}/accounts/:accountID/packages`;
export const FETCH_SEATSINFO_API = `${BASE_URL}/accounts/:accountID/seats/summary`;
export const ADD_PACKAGE_API = `${BASE_URL}/accounts/seats`;
export const UPDATE_PACKAGE_API = `${BASE_URL}/accounts/seats`;
export const FETCH_ACCOUNTS_CONTRACTORS_API = `${BASE_URL}/accounts/:accountID/contractorscompany`;
export const ADD_CONTRACTOR_API = `${BASE_URL}/accounts/:accountID/contractorscompany`;
export const UPDATE_CONTRACTOR_API = `${BASE_URL}/accounts/:accountID/contractorscompany`;
export const DELETE_CONTRACTOR_API = `${BASE_URL}/accounts/:accountID/contractorscompany/:contractorCompanyID`;

//*** WEB BACK OFFICE ***/

// SETUP
export const FETCH_INDUSTRIES_API = `${BASE_URL}/industries`;
export const UPLOAD_COMPANY_IMAGE_API = `${BASE_URL}/accounts/:accountID/logo`;

// JOB TITLES TODO: check and remove unused job title endpoints
export const FETCH_ACCOUNT_JOB_TITLES_API = `${BASE_URL}/accounts/:accountID/jobtitles`;
export const ADD_JOB_TITLE_API = `${BASE_URL}/accounts/:accountID/jobtitles`;
export const UPDATE_JOB_TITLE_API = `${BASE_URL}/accounts/:accountID/jobtitles`;

// PROJECTS
export const FETCH_ACCOUNTS_PROJECTS_API = `${BASE_URL}/accounts/:accountID/projects`;
export const FETCH_USER_PROJECTS_API = `${BASE_URL}/accounts/:accountID/projects/users/:userID/status/all`;
export const ADD_PROJECT_API = `${BASE_URL}/accounts/:accountID/projects`;
export const UPDATE_PROJECT_API = `${BASE_URL}/accounts/:accountID/projects`;
export const DELETE_PROJECT_API = `${BASE_URL}/accounts/:accountID/projects/:projectID`;
export const FETCH_PROJECT_FORMS_API = `${BASE_URL}/accounts/:accountID/projects/:projectID/submissions`;
export const FETCH_PROJECT_FORMS_API_ALL = `${BASE_URL}/accounts/:accountID/submissions`;
export const FETCH_FORM_PDF_API = `${BASE_URL}/submissions/:submissionID/pdf`;

// USER
export const FETCH_ACCOUNT_TEAM_API = `${BASE_URL}/accounts/:accountID/users`;
export const FETCH_USER_TEAM_API = `${BASE_URL}/users/sub-users/:userID`;
export const GET_USER_API = `${BASE_URL}/users/:userID`;
export const FETCH_PROXY_USERS_API = `${BASE_URL}/users/:userID/proxy-users`;
export const CREATE_PROXY_USER_API = `${BASE_URL}/users/:userID/proxy-users/create`;
export const UPDATE_USER_API = `${BASE_URL}/users/:userID`;
export const BLOCK_USERS_API = `${BASE_URL}/users`;
export const UPDATE_SEAT_API = `${BASE_URL}/user/:accountID/update-package/:userID`;
export const SUBMIT_INVITE_API = `${BASE_URL}/accounts/:accountID/users`;
export const RESTORE_PASSWORD_API = `https://safetyassist.auth0.com/dbconnections/change_password`; //TODO: implement according to dev || prod auth0 tenant
export const DELETE_USER_API = `${BASE_URL}/users/:userID`;
export const SUBMIT_HELP_FORM_API = `${BASE_URL}/users/me/requesthelp`;
export const SUBMIT_USER_REGISTER_API = `${API_URL}/Users/:auth0UserID/Profile`; //TODO: check and remove
export const FETCH_USER_API = `${BASE_URL}/users/me`;
export const FETCH_USER_PROFILE_API = `${BASE_URL}/users/me`;
export const FETCH_BACKOFFICE_ACCOUNT_TEAM_API = `${BASE_URL}/accounts/:accountID/users`;
export const SUBMIT_SALES_FORM_API = `${BASE_URL}/users/me/requestsales`;
export const RESEND_USER_INVITE_API = `${BASE_URL}/users/:userID/resend-invite`;
export const USER_CONFIG_API = `${BASE_URL}/users/config/:id`;
export const USER_CERTIFICATES_API = `${BASE_URL}/users/certificates/:id`;
export const MAIL_CERTIFICTAE_API = `${BASE_URL}/users/certificates/mail`;

// FORMS
export const FETCH_FORMS_API = `${BASE_URL}/safetyplans/:planID/forms`;

export const PLAN_SORT_ORDER_API = `${BASE_URL}/safetyplans/:safetyplanID/sortOrder`;

export const REMOVE_REGULATION_IMAGE_API = `${BASE_URL}/safetyplans/forms/:formID/questions/:questionID/regulations/:regulationID/image/`;

// REGISTER
export const REGISTRATION_API = `${BASE_URL}/invites/:inviteID`;

// FORMS INSTANCE
export const FETCH_FORMS_INSTANCE_API = `${BASE_URL}/accounts/:accountID/safetyplans/:safetyPlanID/forms`;
export const ADD_ACCOUNT_FORM_API = `${BASE_URL}/account/:accountID/safetyplans`;
export const DELETE_ACCOUNT_FORM_API = `${BASE_URL}/accounts/:accountID/safetyplans/:safetyPlanID/forms/:formID`;
export const FETCH_PLANS_INSTANCE_API = `${BASE_URL}/accounts/:accountID/safetyplans`;
export const FORMS_SORT_ORDER_API = `${BASE_URL}/safetyplans/forms/:formID/sortOrder`;

// SAFETY PLANS
export const FETCH_SAFETY_PLANS_API = `${BASE_URL}/safetyplans`;
export const ADD_FORM_API = `${BASE_URL}/safetyplans/:planID/forms`;
export const FETCH_SAFETY_SUBFORMS = `${BASE_URL}/safetyplans/forms/:formID/subforms`;
export const ADD_SAFETY_PLAN_API = `${BASE_URL}/safetyplans`;
export const UPDATE_FORM_API = `${BASE_URL}/safetyplans/forms/:formID`;
export const ADD_ACCOUNT_SAFETY_PLAN_API = `${BASE_URL}/account/:accountID/safetyplans`;
export const DELETE_ACCOUNT_SAFETY_PLAN_API = `${BASE_URL}/accounts/:accountID/safetyplans/:safetyPlanID`;
export const UPDATE_PLAN_NAME_API = `${BASE_URL}/safetyplans/:planID`;
export const PLANS_SORT_ORDER_API = `${BASE_URL}/safetyplans/:planID/sortOrder`;
export const REMOVE_SAFETY_PROGRAM_API = `${BASE_URL}/safetyplans/:planID`;

// QUESTIONS
//export const FETCH_QUESTIONS_FULL = `${API_URL}/Questions/QuestionsFull`; //TODO: check and remove
export const FETCH_FORM_QUESTIONS_API = `${BASE_URL}/safetyplans/forms/:formID/questions`;
export const ADD_FORM_QUESTION_API = `${BASE_URL}/safetyplans/:planID/forms/:formID/questions`;
export const UPDATE_FORM_QUESTION_API = `${BASE_URL}/safetyplans/:planID/forms/:formID/questions/:questionID`;
export const REMOVE_FORM_QUESTION_API = `${BASE_URL}/safetyplans/forms/:formID/questions/:questionID`;
export const FETCH_SP_FORMS_QUESTIONS_API = `${BASE_URL}/accounts/:accountID/safetyplans/:safetyplanID/forms/:formID`;
export const SORT_MULTI_QUESTIONS_API = `${BASE_URL}/safetyplans/forms/mq/SortOrder`;
export const FETCH_MULTI_QUESTION_API = `${BASE_URL}/safetyplans/mq/forms/:formID`;
export const QUESTIONS_SORT_ORDER_API = `${BASE_URL}/safetyplans/forms/:formID/question/sortOrder`;

// CORRECTIVE ACTIONS
export const FETCH_ACCOUNT_CA_API = `${BASE_URL}/accounts/actions/:accountID`;
export const CORRECTIVE_ACTIONS_API = `${BASE_URL}/actions`;
export const FETCH_UNDERUSER_CA_API = `${BASE_URL}/actions/users/:userID`;
export const FETCH_CORRECTIVE_ACTION_API = `${BASE_URL}/actions/:accountID/:correctiveActionID`;
export const UPDATE_UNDERUSER_CA_API = `${BASE_URL}/actions/:userID/:correctiveActionID`;
export const FETCH_CA_TRIGGERS_API = `${BASE_URL}/actions/triggers`;
export const FETCH_CA_SEVERITYS_API = `${BASE_URL}/actions/severitys`;
export const FETCH_CA_STATUS_API = `${BASE_URL}/actions/status`;
export const FETCH_CA_TYPES_API = `${BASE_URL}/actions/types`;
export const FETCH_CA_TICKETS_API = `${BASE_URL}/actions/tickets/:accountID/:correctiveActionID`;
export const FETCH_CA_TICKET_API = `${BASE_URL}/actions/ticket/:ticketID`;
export const FETCH_USER_MANAGERS_API = `${BASE_URL}/users/managers/:userID`;

// LICENCES APIS
export const SUBSCRIPATION_TYPES_API = `${BASE_URL}/subscription`;

// PRETASKPLANS APIS
export const PTP_API = `${BASE_URL}/pre-task-plan`;
export const MUTATE_PTP_API = `${BASE_URL}/pre-task-plan/:ptpId`;

export const PTP_TASK_API = `${BASE_URL}/pre-task-plan/task`;
export const MUTATE_PTP_TASK_API = `${BASE_URL}/pre-task-plan/task/:ptpTaskId`;


// PTP REPORTS APIS
export const PTP_SUBMISSIONS_API = `${BASE_URL}/pre-task-plan/submission`;
export const MUTATE_PTP_SUBMISSIONS_API = `${BASE_URL}/pre-task-plan/submission/:submissionId`;
export const GET_PTP_SUBMISSION_API = `${BASE_URL}/submissions/ptp/:ptpSubmissionId/pdf`;


// HAZARDS APIS
export const HAZARD_API = `${BASE_URL}/hazard`;
export const MUTATE_HAZARD_API = `${BASE_URL}/hazard/:hazardId`;

//schedule jobs
export const POST_SCHEDULE_JOBS_API =`${BASE_URL}/accounts/:id/schedulejob`;
export const SCHEDULE_JOBS_API =`${BASE_URL}/accounts/:id/scheduledjobs`;
export const DELETE_SCHEDULED_JOB_API =`${BASE_URL}/accounts/:id/schedulejob/:scheduleJobId`;
export const UPDATE_SCHEDULED_JOB_API =`${BASE_URL}/accounts/:id/schedulejob`;
export const FETCH_SCHEDULED_JOBS_API =`${BASE_URL}/accounts/:accountID/scheduledjobs`;
export const GET_SCHEDULED_JOBS_API =`${BASE_URL}/accounts/:id/schedulejob/:scheduleJobId`;

