// ANTD COMPONENTS
import { Table, Empty, Popconfirm, Dropdown, Menu, Switch, Icon, Button } from 'antd';
import { ScheduleType } from '@shared/types/backend.types';
import React, { useState, useCallback } from 'react';
import EditScheduleModal from './edit.schedule';
import LoadingIndicator from '@shared/floating-button/LoadingIndicator';
import AddScheduleModal from './add.schedule';
import ContactUsButton from '@shared/floating-button/ContactUsButton';

// TYPESCRIPT TYPES
interface IScheduleTableProps {
  schedules: ScheduleType[];
  isLoading: boolean;
  handleEdit: (scheduleJobId: string, jobDetails: any) => Promise<void>;
  handleRemove: (scheduleJobId: string) => Promise<void>;
  handleToggleStatus: (scheduleJobId: string, currentStatus: boolean) => Promise<void>;
  handleSend: (jobDetails: any) => Promise<void>;
  user: any;
  fetchSchedules?: () => Promise<void>;
}

const ScheduleTableComponent: React.FC<IScheduleTableProps> = ({
  schedules,
  isLoading,
  handleRemove,
  handleEdit,
  handleToggleStatus,
  handleSend,
  user,
  fetchSchedules,
}) => {
  const columns = [
    {
      title: <b>Scheduled Report Title</b>,
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: <b>Type</b>,
      dataIndex: 'activityType',
      key: 'activityType',
      render: (activityType: string) => {
        if (activityType === 'submissions_report') {
          return 'Submission Report';
        } else if (activityType === 'ca_report') {
          return 'Corrective Action Report';
        } else if (activityType === 'ptp_submissions_report') {
          return 'Pre Task Plan Report';
        } else if (activityType === 'cc_report') {
          return 'Contractor Companies Report';
        } else if (activityType === 'projects_report') {
          return 'Projects Report';
        } else if (activityType === 'associates_report') {
          return 'Associates Report';
        }
        return activityType || <b className="sa-clickable">N/A</b>;
      },
    },
    {
      title: <b>Frequency</b>,
      dataIndex: 'jobFrequency',
      key: 'jobFrequency',
      render: (text: any) => text.replace(/\b\w/g, (char: any) => char.toUpperCase()),
    },
    {
      title: <b>Time</b>,
      dataIndex: 'jobTime',
      key: 'jobTime',
      render: (time: string) => time || <b className="sa-clickable">N/A</b>,
    },
    {
      title: <b>Recipient Emails</b>,
      dataIndex: 'recipientEmails',
      key: 'recipientEmails',
      render: (recipientEmails: string) => recipientEmails || <b className="sa-clickable">N/A</b>,
    },
    {
      title: <b>Status</b>,
      dataIndex: 'status',
      key: 'status',
      render: (text: any) => {
        const formattedText = text.charAt(0).toUpperCase() + text.slice(1);
        const color = text.toLowerCase() === 'active' ? 'green' : 'red';
        return <span style={{ color }}>{formattedText}</span>;
      },
    },

    {
      title: <b>Actions</b>,
      key: 'actions',
      render: (_: any, record: any) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item>
                <a onClick={() => handleSend(record)}>
                  <Icon type="export" />

                  <b style={{ margin: '0px 10px', fontSize: '1.0rem' }}>&nbsp;Export Report</b>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => handleEditClick(record)}>
                  <Icon type="edit" />
                  <b style={{ margin: '0px 10px', fontSize: '1.0rem' }}>&nbsp;Edit Report</b>
                </a>
              </Menu.Item>
              <Menu.Item>
                <Popconfirm
                  title="Are you sure you want to delete this report?"
                  onConfirm={() => handleRemove(record.scheduleJobId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="team-actions-dropdown-content">
                    <Icon type="user-delete" />
                    <b style={{ margin: '0px 10px', fontSize: '1.0rem' }}>Delete Report&nbsp;</b>
                  </div>
                </Popconfirm>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <Icon type="more" />
          </a>
        </Dropdown>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any | null>(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const handleEditClick = (schedule: ScheduleType) => {
    setSelectedSchedule(schedule);
    setIsModalVisible(true);
  };

  const handleUpdateSchedule = async (jobDetails: any) => {
    if (selectedSchedule) {
      await handleEdit(selectedSchedule.scheduleJobId, jobDetails);
      setIsModalVisible(false);
      setSelectedSchedule(null);
    }
  };

  const handleCreateSchedule = async (newJobDetails: any) => {
    console.log('Creating new schedule:', newJobDetails);
    setIsAddModalVisible(false);
  };

  const reloadSchedules = useCallback(async () => {
    if (fetchSchedules) {
      setIsAddModalVisible(false)
      await fetchSchedules(); 
    }
  }, [fetchSchedules]);

 

  return (
    <div className="sa__container">
      <div className="sa__box">
        <div className="sa__box-heading">
          <h4 className="sa__box-heading-title">Scheduled Reports List</h4>
          <Button
            type="primary"
            style={{ marginLeft: 'auto' }}
            onClick={() => setIsAddModalVisible(true)}
          >
            Create New Report
          </Button>
        </div>

        <div className="sa__box-content">
          {isLoading ? (
            <div>
              <LoadingIndicator />
            </div>
          ) : schedules.length ? (
            <Table
              columns={columns}
              dataSource={schedules}
              pagination={false}
              rowKey="scheduleJobId"
              bordered
            />
          ) : (
            <Empty description="No Schedule Jobs Found" />
          )}
        </div>
      </div>
      <EditScheduleModal
        visible={isModalVisible}
        schedule={selectedSchedule}
        onClose={() => setIsModalVisible(false)}
        onUpdate={handleUpdateSchedule}
      />

      <ContactUsButton
        user={user}
        openModal={isAddModalVisible}
        onClose={reloadSchedules}
        onSubmit={reloadSchedules}
      />
    </div>
  );
};

export default ScheduleTableComponent;
